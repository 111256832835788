<template>
    <div>
        <InertiaLink v-if="$can('view users')" :href="route('users.index')" class="px-2 text-sm text-gray-600">
            {{ __('Users') }}
        </InertiaLink>
        <InertiaLink v-if="$can('view roles')" :href="route('roles.index')" class="px-2 text-sm text-gray-600">
            {{ __('Roles') }}
        </InertiaLink>
        <InertiaLink v-if="$can('view forms')" :href="route('forms.index')" class="px-2 text-sm text-gray-600">
            {{ __('Forms') }}
        </InertiaLink>
        <InertiaLink
            v-if="$can('view formgroups')"
            :href="route('form-groups.index')"
            class="px-2 text-sm text-gray-600"
        >
            {{ __('Form Groups') }}
        </InertiaLink>
        <InertiaLink v-if="$can('view invites')" :href="route('invites.index')" class="px-2 text-sm text-gray-600">
            {{ settings.general.invite_name_plural }}
        </InertiaLink>
        <InertiaLink v-if="$can('export excel')" :href="route('exports.index')" class="px-2 text-sm text-gray-600">
            {{ __('Exports') }}
        </InertiaLink>
        <InertiaLink v-if="$can('view files')" :href="route('files.index')" class="px-2 text-sm text-gray-600">
            {{ __('Files') }}
        </InertiaLink>
        <InertiaLink v-if="$can('view settings')" :href="route('settings.index')" class="px-2 text-sm text-gray-600">
            {{ __('Settings') }}
        </InertiaLink>
    </div>
</template>

<script setup lang="ts">
import { Link as InertiaLink, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const page = usePage<AuthenticatedPageProps>();
const settings = computed(() => page.props.settings);
</script>
